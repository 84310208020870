import React from "react"
import { Container, Row, Col } from "reactstrap";
import styled, { keyframes } from "styled-components";

import Layout from "../components/layout";
import SEO from "../components/seo";
import TrainingBody from "../components/TrainingBody"

import TrainingBG from "../images/training/BACKGGROUND1-01.png";
import Banner from "../images/training/Modules/Module03/img01.png";

import img01 from "../images/training/Modules/Module03/img02.png";
import img02 from "../images/training/Modules/Module02/img03.jpg";

const benefitlist = [
  "Learn how to operate and handle 3D Printers",
  "Learn how to troubleshoot and repair the 3D Printers",
  "Get to know the possibilities and restrictions in every AM technologies"
]

const moduledetails01 = [
  "Overiew of AM Materials (polymers and materials)", 
  "Comparison of Material characteristics from different AM Technologies", 
  "Introduction to raw materials (e.g Powder, Filament, Wire)"
]
const moduledetails02 = [
  "Methods of Handling 3D Printing machines for different technology of AM", 
  "Practical Application", 
]
const moduledetails03 = [
  "Const and time Estimations for different technologies Printer-Handling", 
  "Practical Application", 
]


const TrainingAdvancePage = () => (
  <Layout>
    <SEO title="Advance 3D Printing Training" description="Advance 3D Printing Training" />
    
    <TrainingBody
      Banner={Banner}
      Title="Advance 3D Printing Training"
      TitleDesc="Experience the Power of Advanced 3D Printing. Get Hand-Ons with Cutting-Edge Techonology. Our Comprehensive training course provides participants with the unique opportunity to interact with a rang of stat-of-the-art 3D Printing machine. Gain practical expertise in various 3D Printing Technologies and learn to confidentky handle and operate these innovative machines. Unlock the full potential of 3D Printing with our immersive training program."
      BenefitList={benefitlist}
      ModuleList01="Data Preparation & Material Selection (Hands-On)"
      ModuleListDetails01={moduledetails01}
      ModuleList02="Handling & Operation 3D Printing (Hands-On)"
      ModuleListDetails02={moduledetails02}
      ModuleList03="Post Processing (Hands-On)"
      ModuleListDetails03={moduledetails03}
      ModuleImg01={img01}
      ModuleImg02={img02}
    />

  </Layout>
)

export default TrainingAdvancePage
